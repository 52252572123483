import isEmpty from "lodash/isEmpty";

import { useStrainPageContext } from "context/StrainPageContext";
import { useEventTracker } from "hooks/useEventTracker";
import { extractStrainSensations } from "utils/extractStrainSensations";

import PlusStylizedIcon from "components/Icons/plus_stylized.svg";
import ThumbDownStylizedIcon from "components/Icons/thumb_down_stylized.svg";
import ThumbUpStylizedIcon from "components/Icons/thumb_up_stylized.svg";

import StrainAttributes from "./StrainAttributes";

const StrainHighlights: React.FC<{
  showAllHighlights?: boolean;
}> = ({ showAllHighlights = true }) => {
  const { strain } = useStrainPageContext();
  const { publishEvent } = useEventTracker();

  if (isEmpty(strain)) {
    return <></>;
  }

  const { id: strainId } = strain;

  const negatives = strain.negatives
    ? extractStrainSensations(strain.negatives).slice(0, 3)
    : [];
  const feelings = extractStrainSensations(strain.effects).slice(0, 3);
  const helpsWith = extractStrainSensations(
    {
      ...strain.symptoms,
      ...strain.conditions,
    },
    "votes",
  ).slice(0, 3);

  let hasNoHighlights = false;

  if (
    negatives.length === 0 &&
    feelings.length === 0 &&
    helpsWith.length === 0
  ) {
    hasNoHighlights = true;
  }

  return (
    <>
      {hasNoHighlights ? (
        <></>
      ) : (
        <div className="border border-light-grey rounded">
          <div className="p-4">
            <ul className="space-y-2">
              <li className="text-xs">
                <h4 className="text-xs">STRAIN HIGHLIGHTS</h4>
              </li>
              <li className="h-5 flex flex-row items-baseline">
                {feelings.length > 0 ? (
                  <>
                    <span className="relative top-1">
                      <ThumbUpStylizedIcon />
                    </span>
                    <div className="pl-1.5 flex items-baseline text-xs">
                      Feelings:
                      {feelings.map((feeling, idx) => {
                        const slug = feeling.name;
                        return (
                          <div key={slug}>
                            {idx > 0 && (
                              <span
                                className="relative bottom-1 text-green"
                                aria-hidden="true"
                              >
                                .
                              </span>
                            )}
                            <a
                              className="py-1.5 px-1 text-green"
                              href="#strain-sensations-section"
                              onClick={() => {
                                publishEvent({
                                  action: "click",
                                  category: "strain highlights",
                                  label: "feelings",
                                  strainId,
                                });
                              }}
                            >
                              {slug}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </li>
              <li className="flex flex-row items-baseline">
                {negatives.length > 0 ? (
                  <>
                    <span className="relative top-1">
                      <ThumbDownStylizedIcon />
                    </span>
                    <div className="pl-1.5 flex items-baseline text-xs">
                      Negatives:
                      {negatives.map((negative, idx) => {
                        const slug = negative.name;
                        return (
                          <div key={slug}>
                            {idx > 0 && (
                              <span
                                className="relative bottom-1 text-green"
                                aria-hidden="true"
                              >
                                .
                              </span>
                            )}
                            <a
                              className="py-1.5 px-1 text-green"
                              href="#strain-sensations-section"
                              onClick={() => {
                                publishEvent({
                                  action: "click",
                                  category: "strain highlights",
                                  label: "negatives",
                                  strainId,
                                });
                              }}
                            >
                              {slug}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </li>
              <li className="flex flex-row items-baseline">
                {helpsWith.length > 0 && showAllHighlights ? (
                  <>
                    <span className="relative top-1">
                      <PlusStylizedIcon />
                    </span>
                    <div className="pl-1.5 flex items-baseline text-xs">
                      Helps with:
                      {helpsWith.map((help, idx) => {
                        const slug = help.name;
                        return (
                          <div key={slug}>
                            {idx > 0 && (
                              <span
                                className="relative bottom-1 text-green"
                                aria-hidden="true"
                              >
                                .
                              </span>
                            )}
                            <a
                              className="py-1.5 px-1 text-green"
                              href="#helps-with-section"
                              onClick={() => {
                                publishEvent({
                                  action: "click",
                                  category: "strain highlights",
                                  label: "helps with",
                                  strainId,
                                });
                              }}
                            >
                              {slug}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </li>

              <li>
                <StrainAttributes className="pt-0" />
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default StrainHighlights;
