export function cdfNormal(x: number, mean = 15, standardDeviation = 5): number {
  if (!x) {
    return 0;
  }
  return (1 - erf((mean - x) / (Math.sqrt(2) * standardDeviation))) / 2;
}

function erf(num: number) {
  /*
  This version of erf was adopted from https://stackoverflow.com/questions/14846767/std-normal-cdf-normal-cdf-or-error-function
  Which is adopted from Abramowitz and Stegun's Handbook of Mathematical Functions
  http://people.math.sfu.ca/~cbm/aands/frameindex.htm
  */

  // save the sign of x
  const sign = num >= 0 ? 1 : -1;
  const x = Math.abs(num);

  // constants
  const a1 = 0.254829592;
  const a2 = -0.284496736;
  const a3 = 1.421413741;
  const a4 = -1.453152027;
  const a5 = 1.061405429;
  const p = 0.3275911;

  // A&S formula 7.1.26
  // eslint-disable-next-line prettier/prettier -- Prettier does not like the no-mixed-operators eslint rule, but we do :)
  const t = 1.0 / (1.0 + (p * x));
  const y =
    // eslint-disable-next-line no-mixed-operators -- A little too complex to remedy this for linting purposes.
    1.0 - ((((a5 * t + a4) * t + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);
  return sign * y; // erf(-x) = -erf(x);
}
