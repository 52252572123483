import React from "react";
import classNames from "classnames";

type Props = {
  className?: string;
  leftLabel: string;
  percentage: number;
  rightLabel: string;
  screenReaderText: string;
};

// an aria-hidden tag is applied to the scale itself since the element and its labels
// aren't really laid out in a screen readable way that would make sense
// the screen reader text should be added to accurately describe these
const Scale = ({
  className,
  leftLabel,
  percentage,
  rightLabel,
  screenReaderText,
}: Props): JSX.Element => (
  <>
    <div
      className={classNames(className, "sm:min-w-[280px]")}
      aria-hidden={true}
    >
      <div className="flex text-xs items-center justify-between pb-xs">
        <span>{leftLabel}</span>
        <span>{rightLabel}</span>
      </div>

      <div
        style={{ height: "10px" }}
        className="bg-light-grey rounded-full relative"
      >
        {percentage && (
          <div
            className="bg-default rounded-full"
            style={{
              height: "10px",
              width: `${percentage}%`,
            }}
          />
        )}
      </div>
    </div>
    <p className="sr-only">{screenReaderText}</p>
  </>
);

export default Scale;
