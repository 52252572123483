import React from "react";
import classNames from "classnames";

type VariantOption = "cannabinoid" | "phenotype" | "phenotypeRedesign";

type Props = {
  className?: string;
  children: JSX.Element | JSX.Element[] | string;
  variant: VariantOption;
};

const BACKGROUND_COLORS: { [Variant in VariantOption]: string } = {
  cannabinoid: "bg-deep-green-20",
  phenotype: "bg-leafly-white",
  phenotypeRedesign: "bg-white",
};

const Classification: React.FC<Props> = ({
  className,
  variant,
  children,
  ...others
}) => {
  const classes = classNames(
    "inline-block text-xs px-sm rounded font-bold text-default",
    BACKGROUND_COLORS[variant],
    className,
    {
      "border border-light-grey py-0": variant === "phenotypeRedesign",
      "py-xs": variant !== "phenotypeRedesign",
    },
  );

  return (
    <span className={classes} {...others}>
      {children}
    </span>
  );
};

export default Classification;
